/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-05-21 17:25:55
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-08-24 11:39:18
 */
import config from './config/finance'
import axios from '@/utils/request'
export const getOrderList = params => axios.post(config.orderList, params) // 订单列表
export const getOrderDetail = params => axios.post(config.orderDetail, params) // 订单列表
export const getIncomeList = params => axios.post(config.incomeList, params) // 收入列表
export const getIncomeShow = params => axios.post(config.showDetailed, params) // 展示收支信息
// export const getIncomeDownLoad = params => axios.post(config.downLoad, params) //导出收入明细
export const frozenDetail = params => axios.post(config.frozenDetail, params) // 冻结详情列表
export const forzenInfoDetail = params => axios.post(config.forzenInfoDetail, params) // 冻结详情
export const orderListExport = params =>
  axios.post(config.orderListExport, params, { responseType: 'blob' }) // 订单导出
