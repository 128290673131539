/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-05-21 17:25:55
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-08-24 14:10:28
 */
export default {
  orderList: '/order/findMyOrder', // 订单列表
  orderDetail: '/order/findMyOrderDetail', // 订单详情
  incomeList: '/order/findMySerial', // 收入列表
  // downLoad: '/order/identify/adminOrder/downLoadSerial', //下載收支
  showDetailed: '/order/identify/amount/merchant/showAmountDetailed', //展示收支
  frozenDetail: '/order/forzenInfo', //冻结详情列表
  forzenInfoDetail: '/order/forzenInfoDetail', //冻结详情
  orderListExport: '/order/exportMyOrder', //导出订单
}
