<template>
  <div>
    <el-card class="box-card">
      <div class="orderHeader">
        <div class="orderDetail">
          <div class="orderMoney">当前冻结总额：</div>
          <div class="money">￥{{ incomeData.forzenSumAmount }}</div>
        </div>
      </div>
      <div class="explain">
        说明：为保障客户权益，平台会进行审核，审核周期为30天，当审核通过后，订单支付款项解冻，可进行提现。
      </div>
      <table-list
        class="tableList"
        :title="tableTitle"
        :loading="loading"
        :data="frozenList"
        :options="{ selection: true }"
        :columns="columns(this)"
        :operates="operates(this)"
        :pager="pager"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></table-list>
    </el-card>
  </div>
</template>

<script>
//表格标题
const tableTitle = '冻结明细'
// 表格列配置
const columns = () => [
  {
    prop: 'orderId',
    label: '订单编号',
  },
  {
    prop: 'productName',
    label: '订单内容',
  },
  {
    prop: 'orgIncome',
    label: '实付金额',
    render: (h, { row }) => {
      return h('div', [h('div', row.orgIncome ? '￥' + row.orgIncome : '')])
    },
  },
  {
    prop: 'showCommissionRate',
    label: '抽成比例',
  },
  {
    prop: 'showOrgIncome',
    label: '实际效益',
  },
  {
    prop: 'showStatus',
    label: '状态',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '详情',
    method: _this.handleDetail,
  },
]
import { getIncomeShow, frozenDetail } from '@/api/finance'
import to from 'await-to'
import TableList from '@/components/TableList'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      tableTitle,
      columns,
      operates,
      incomeData: {},
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      frozenList: [],
      loading: false,
    }
  },
  created() {
    this.getIncomeShowData()
    this.frozenDetail()
  },
  methods: {
    async frozenDetail() {
      this.loading = true
      const [res, err] = await to(frozenDetail({ ...this.pager }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.frozenList = res.data.list
      this.pager.total = res.data.total
    },
    async getIncomeShowData() {
      const [res, err] = await to(getIncomeShow())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.incomeData = res.data
    },
    handleDetail(row) {
      this.$router.push({ path: 'lookFrozenDetail', query: { orderId: row.orderId } })
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getIncomeListData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getIncomeListData()
    },
  },
}
</script>

<style scoped lang="scss">
.orderHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  .orderDetail {
    display: flex;
    align-items: center;
    .orderMoney {
      font-size: 16px;
      color: #333;
    }
    .money {
      color: #f8341e;
      font-size: 20px;
      font-weight: 500;
      margin-right: 40px;
    }
  }
}
.explain {
  font-size: 14px;
  color: #ff7b33;
  margin-bottom: 30px;
}
</style>
